/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, ColumnCover, ColumnDiv, PriceWrap, Subtitle } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1l27lra bg--center --full" name={"einleitung"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Title className="title-box fs--72 w--900 ls--0" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">Alle Mauer – und Maurerarbeiten für guten Preis</span>"}>
              </Title>

              <Button className="btn-box swpf--uppercase" content={"Dienstleistungen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"dienstleistungen-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s4 el--1 pb--20 pt--20" anim={"5"} animS={"4"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Wir werden durchführen"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"dienstleistungen-2"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 --full flex--center" style={{"maxWidth":1540}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box" content={"ab 49€ / m2"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box" content={"ab 49€ / m2"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box" content={"ab 49€ / m2"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"dienstleistungen-3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s4 el--2 pb--20 pt--20 flex--top" anim={"5"} animS={"4"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Weiteres ..."}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":560}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-szp6dj --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4 pb--80 pt--80" anim={"7"} animS={"4"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">Vom Pflaster bis zur Fassade</span>"}>
              </Title>

              <Button className="btn-box swpf--uppercase" content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"presliste"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s4 el--2 pb--20 pt--20" anim={"5"} animS={"4"} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Presliste"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":417}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Wandbau"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Verputzen"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Fußboden betonieren"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Wandbau"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Verputzen"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Fußboden betonieren"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Wandbau"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Verputzen"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Fußboden betonieren"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Wandbau"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Verputzen"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--500" content={"Fußboden betonieren"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"ab 49€ / m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-szp6dj --parallax pb--80 pt--80" name={"partei"} layout={"l1"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--3 pb--20 pt--20 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Dobschal</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\"><a href=\"https://saywebpage.com\">Webproduktion stránek</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":269}} content={"<span style=\"color: white;\">Oeder Weg 22<br>+49 797 811 2X05<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Dienstleistungen / Presliste</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Fotogalerie</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Nach oben</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}